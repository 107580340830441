<template>
    <div class="main">
        <Title >公司简介</Title>
        <Pgp class="wow fadeIn">历思长期致力于发展智慧物证科技，为社会提供可信鉴定服务；目前在文书痕迹、声像资料、微量物证鉴定等多个方面，其软硬件条件和检验技术已达国内先进水平；特别是在毒品鉴定、食药环鉴定、文物艺术品鉴定等领域已成为行业引领者。

</Pgp>
<Pgp class="wow fadeIn">
    历思是国内禁毒领域领先的司法鉴定机构，在血液、尿液、毛发、成品、原料、环境的毒检方面均有深入研究；近年来承担了福建省大量涉毒案件鉴定工作，与省内各地市警方就制贩毒、查吸、在册人员管控、毒情监测分析、毒情逆向追踪等展开系统性合作，并长期接收来自海南、江苏、湖南、湖北、河北、河南、浙江、江西、山东、山西、广东、广西等全国各地的涉毒案件。
</Pgp>
        <ImagePreview class="col3 wow slideInRight" style="margin:0 auto" :src="src" :srcList="[src]"></ImagePreview>
        <div class="box">
            <div class="title-box">
                <img class="icon" :src="require('../../assets/Domain/Auth/2.png')" />
                <div class="title">福建历思司法鉴定所</div>
                
            </div>
            <Pgp class="wow fadeInDown">
                福建历思司法鉴定所是历思物证科技产研体系的核心成员单位，于2006年4月经福建省司法厅核准登记成立（统一社会信用代码：3435000078693711X0），为国内司法鉴定管理体制改革后成立的第一批独立的第三方司法鉴定机构。

            </Pgp>
        </div>
        <div class="box">
            <div class="title-box">
                <img class="icon" :src="require('../../assets/Domain/Auth/2.png')" />
                <div class="title">福建历思司法鉴定所泉州分所</div>
                
            </div>
            <Pgp class="wow fadeInDown">
                福建历思司法鉴定所泉州分所是泉州市最早成立的文书、痕迹司法鉴定机构，在文书形成时间检验及篡改（污损）文件检验等方面优势突出。
            </Pgp>
            <Pgp class="wow fadeInDown">
                十多年来承担了泉州地区文痕鉴定的主要任务。
            </Pgp>
        </div>
        <div class="box">
            <div class="title-box">
                <img class="icon" :src="require('../../assets/Domain/Auth/2.png')" />
                <div class="title">福州堃晟检测技术有限公司司法鉴定所</div>
                
            </div>
            <Pgp class="wow fadeInDown">
                福州堃晟检测技术有限公司司法鉴定所主要服务于福州及周边地区。在先进仪器及前沿技术支撑下，可高速有效地做好毒品检测鉴定工作。为配合公安部门工作，机构人员随时待命，以实现对禁毒工作的快速响应、高效协助。

            </Pgp>
        </div>
        <div class="box">
            <div class="title-box">
                <!-- <img class="icon" :src="require('../../assets/Domain/Auth/2.png')" /> -->
                <div class="title">「资质齐全 综合服务」</div>
                
            </div>
            <p style="    margin: 0px auto;
    max-width: 364px;
    letter-spacing: 2px;
    font-size: 0.8rem;">
              目前司法鉴定共有四大类18项资质，历思拥有除法医精神病鉴定外的全部17大项。
            </p>
            <p class="title_type1 center flex" style="margin:1rem 0 1rem 0 ">法医类司法鉴定</p>
            <div class="flex wrap" style="margin-bottom:3rem;">
              <div class="col25" v-for="item in data[0]" :key="item[0]">
                <ImagePreview :height="'90%'" :width="'90%'" class="hvr-push img" :src="require('../../assets/Domain/Auth/'+item[0])" :srcList="[require('../../assets/Domain/Auth/'+item[0])]">
                    <p class="img-text">{{item[1]}}</p>
                </ImagePreview>
             </div>
            </div>
            <p class="title_type1 center flex" style="margin:1rem 0 1rem 0 ">物证类司法鉴定</p>
            <div class="flex wrap" style="margin-bottom:2rem;">
              <div class="col3" v-for="item in data[1]" :key="item[0]">
                <ImagePreview :height="'90%'" :width="'90%'" class="hvr-push img" :src="require('../../assets/Domain/Auth/'+item[0])" :srcList="[require('../../assets/Domain/Auth/'+item[0])]">
                    <p class="img-text">{{item[1]}}</p>
                </ImagePreview>
             </div>
            </div>
            <p class="title_type1 center flex" style="margin:1rem 0 1rem 0 ">声像资料司法鉴定</p>
            <div class="flex wrap" style="margin-bottom:2rem;">
              <div class="col3" v-for="item in data[2]" :key="item[0]">
                <ImagePreview :height="'90%'" :width="'90%'" class="hvr-push img" :src="require('../../assets/Domain/Auth/'+item[0])" :srcList="[require('../../assets/Domain/Auth/'+item[0])]">
                    <p class="img-text">{{item[1]}}</p>
                </ImagePreview>
             </div>
            </div>
            <p class="title_type1 center flex" style="margin:1rem 0 1rem 0 ">环境损害司法鉴定</p>
            <div class="flex wrap col7-3" style="margin-bottom:2rem;height:200px">
              <div class="col3" >
                <ImagePreview :height="'200px'" :width="'100%'" class="img" :src="require('../../assets/Domain/Auth/'+data[3][0][0])" :srcList="[require('../../assets/Domain/Auth/'+data[3][0][0])]">
                </ImagePreview>
             </div>
              <div class="col7" >
                <ImagePreview :height="'200px'" :width="'100%'" class="img" :src="require('../../assets/Domain/Auth/'+data[3][1][0])" :srcList="[require('../../assets/Domain/Auth/'+data[3][1][0])]">
                </ImagePreview>
             </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import src from '../../assets/Domain/Auth/1.png';
export default {
    data(){
        return {
            src:src,
            data:[
              [
                ['1-1.png','法医物证鉴定'],
                ['1-2.jpg','法医临床鉴定'],
                ['1-3.jpg','法医毒物鉴定'],
                ['1-4.png','法医病理鉴定'],
                
              ],
              [
                ['2-1.jpg','微量物证鉴定'],
                ['2-2.jpg','文书鉴定'],
                ['2-3.png','痕迹鉴定'],
              ],
              [
                ['3-1.jpg','录音鉴定'],
                ['3-2.png','图像鉴定'],
                ['3-3.jpg','电子数据鉴定'],
              ],
              [
                ['4-1.png'],
                ['4-2.png'],
              ],
            ]
        }
    }
}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>

.main{
    width:80vw;
    margin:0 auto;
    max-width:1600px;
}
.img{
    /* width:300px; */
    /* margin:0 auto; */
    width:100%;
}
.icon{
    width:30px;
    height:30px;
    margin-right:10px;
}
.title-box{
    margin:1rem 0;
    font-size:1.3rem;
    color:#027DB4;
    display:flex;
    text-align:left;
    align-items:center;
    font-weight:bold;
}
.box{
    margin:3rem 0;
}
.title_type1{
  font-weight:bold;
}
</style>